<template>
  <v-app>
    <router-view />
    <notifications />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
.shepCodeEditor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #454545;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 8px;
  border-radius: 4px;
}
.prism-editor__textarea:focus {
  outline: none;
}
</style>
