<template>
  <div class="sPage">
    <v-navigation-drawer permanent dark>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title sLogo" @click="route('home')">
            Shepherd
          </v-list-item-title>
          <v-list-item-subtitle>
            Wrangle those sheep
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>
      <v-list
        dense
        nav
      >
        <template v-for="(nav, i) of navMenu">
          <v-list-group
            v-if="nav.children"
            :key="i"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i :class="`fad ${nav.icon} sIconNav`"/>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ nav.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, j) of nav.children"
              :key="i + '-' + j"
              :to="child.routeName ? { name: child.routeName } : null"
              @click="child.action ? child.action() : false"
            >
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="i"
            :to="nav.routeName ? { name: nav.routeName } : null"
            @click="nav.action ? nav.action() : false">
            <v-list-item-icon>
              <i :class="`fad ${nav.icon} sIconNav`"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ nav.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <div class="sContent">
      <v-content>
        <router-view/>
      </v-content>
    </div>
  </div>
</template>
<script>
import {auth} from "../firestore";
import scssVars from "../scss/shepherd-vars.scss";

export default {
  data() {
    return {
      navMenu: [
        {icon: "fa-rabbit-fast", title: "Runner", routeName: "runner-job-list"},
        {icon: "fa-server", title: "Deployer", routeName: "deployer-list"},
        {icon: "fa-portal-enter", title: "Service Ports", routeName: "service-port"},
        {icon: "fa-database", title: "DB Schema", routeName: "db-schema-project-list"},
        {
          icon: "fa-hands-heart", title: "Welfare", children: [
            {title: "Project List", routeName: "welfare-project-list"},
            {title: "Team List", routeName: "welfare-team-list"},
          ],
        },
        {icon: "fa-tachometer-alt", title: "Dashboard", routeName: "dashboard"},
        {icon: "fa-computer-classic", title: "Code", routeName:"code list"},
        {icon: "fa-sign-out", title: "Logout", action: this.logout},
      ],
    };
  },
  methods: {
    logout() {
      auth.signOut().then(() => {
          this.$router.push({name: "account-login"});
        },
        () => {
          this.$notify({
            type: "error",
            title: "Firebase Auth Error",
          });
        });
    },
    route(name) {
      this.$router.push({name});
    },
    routeName() {
      return this.$route.name;
    },
    scssColor(type) {
      return scssVars[`color${type}`];
    },
  },
};
</script>

<style scoped lang="sass">
@import "@/scss/shepherd-vars.scss"
.sPage
  width: 100vw
  height: 100vh
  background: $colorGrey1
  display: grid
  grid-template-columns: 220px auto
  overflow-y: hidden

  i.sIconNav
    font-size: 24px

  .sLogo
    font-family: $fontLogo !important
    color: $colorOrange
    font-size: 2em !important
    cursor: pointer

  .sNav
    height: 100vh
    overflow-y: auto

  .sContent
    height: 100vh
    background: $colorGrey2
    color: $colorGrey3
    position: relative
    padding: 50px
    overflow-y: auto
</style>
