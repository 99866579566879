import Vue from 'vue';
import { firestorePlugin } from 'vuefire';
import Notifications from 'vue-notification';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
require('./filter.');

Vue.use(firestorePlugin);
Vue.use(Notifications);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
